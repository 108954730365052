html,
body,
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

:root {
    --primaryColor: #282a57;
    --secondaryColor: #e4e4e4;
    
    --headlineBig: clamp(5rem, 9.9vw, 15rem);
    --headlineMedium: clamp(1rem, 2vw, 4rem);
    --headlineSmall: clamp(0.6rem, 0.75vw, 1.3rem);
    --spacing: clamp(0.9rem, 0.75vw, 1.3rem);
    
}

body {
    font-family: 'Montserrat', sans-serif;
    background-color: var(--primaryColor);
    color: var(--secondaryColor);
}