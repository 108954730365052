.about-container {
    font-family: Arial, sans-serif;
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.greeting {
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 20px;
}

.bio-section {
    width: 100%;  
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.bio-section p {
    line-height: 1.5;
}

.profile-pic {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 20px;
}

.social-section {
    margin: 30px 0;
    display: flex;
    gap: 20px;
    justify-content: center;
}

.social-section a {
    color: #ffffff;
    font-size: 24px;
    text-decoration: none;
    transition: color 0.3s;
}

.social-section a:hover {
    color: #555;
}

.resume-section {
    margin-top: 20px;
}

.resume-link {
    background-color: #572841;
    color: #FFF;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.resume-link:hover {
    background-color: #2b1420;
}
