  
  .resume-body .resume-container{
	width: 100%;
	padding: 35px 10%;
  }
  
  .resume-body .resume-container{
	padding-right: calc(10% - 30px);
  }
  
  .resume-body .resume-main.row{
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 20px;
  }
  
  .resume-body .resume-col header.resume-title{
	font-family: helvetica;
	color: #ffffff;
	padding: 0px 0px 20px 30px;
  }
  
  .resume-body .resume-col .resume-contents{
	padding: 0px 30px;
	border-left: 2px solid #bababa;
  }
  
  .resume-body .resume-col .resume-contents .resume-box{
	position: relative;
	padding: 20px;
	border: 1px solid #eaeaea;
	background-color: #ffffff;
	cursor: pointer;
	transition: all 0.4s;
	margin-bottom: 20px;
  }
  
  .resume-body .resume-col .resume-contents .resume-box:hover{
	box-shadow: 0px 3px 12px 0px #ccc;
	border: 1px solid transparent;
  }
  
  .resume-body .resume-col .resume-contents .resume-box::before{
	content: '';
	position: absolute;
	width: 16px;
	height: 16px;
	border-radius: 50%;
	right: calc(100% + 22px);
	top: 0;
	background-color: #572841;
	border: 2px solid white;
  }
  
  .resume-body .resume-box h4{
	position: relative;
	color: #572841;
  }
  
  .resume-body .resume-box h3 {
	font-size: 19px;
	padding: 10px 0px 6px;
	font-family: helvetica;
	color: #444;
	letter-spacing: normal; 
  }
  .resume-body .resume-box .resume-detail {
	font-size: 14px; /* Smaller font size */
	font-style: italic; /* Italicize the text */
	color: #666; /* Optional: you can change the color */
	display: block; /* To make it appear on a new line */
	margin-bottom: 5px;
  }
  
  
  .resume-body .resume-box p{
	line-height: 1.2;
	color: #666;
	font-size: 17px;
  }
  
  @media(max-width: 768px){
	.resume-body .resume-main.row{
	  grid-template-columns: 1fr;
	}
  
	.resume-body .row .resume-col:nth-child(2){
	  margin-top: 30px;
	}
  }
  