body {
    font-family: 'Montserrat', sans-serif;
    background-color: var(--primaryColor);
    color: var(--secondaryColor);
}
.parallax-outer {
    overflow: hidden;
}

.parallax {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 125vh;
    width: 100%;
    position: relative;
}

.parallax img {
    position: absolute;
}


.mountain-3,
.mountain-2,
.mountain-1 {
    width: 100%;
    
    bottom: 0;
    z-index: 3;
}

.mountain-2 {
    bottom: 10px;
    z-index: 2;
}

.mountain-1 {
    bottom: 40px;
    z-index: 1;
}

.sun {
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40%;
}

.clouds-left {
    left: 0;
    width: 20%;
}

.clouds-right {
    right: 0;
    width: 20%;
}

.clouds-bottom {
    bottom: 0px;
    width: 100%;
}

.stars {
    top: -560px;
    left: 0;
    width: 100%;
}

.copy {
    position: absolute;

    bottom: 0%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
    color: var(--secondaryColor);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    opacity: 0;
}

.copy h1 {
    font-size: 10rem;
}

.copy span {
    background-color: var(--secondaryColor);
    color: var(--primaryColor);
    padding: 1rem;
    font-weight: 800;
    border-radius: 0.5rem;
    opacity: 0;
}